import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  {
    path: 'utilidades', 
    loadChildren: () => import('./pages/utilidades/utilidades.module').then(m => m.UtilidadesModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'mantenimientos', 
    loadChildren: () => import('./pages/mantenimientos/mantenimientos.module').then(m => m.MantenimientosModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'ventas', 
    loadChildren: () => import('./pages/ventas/ventas.module').then(m => m.VentasModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/camping', 
    loadChildren: () => import('./reports/camping/camping.module').then(m => m.RCampingModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/almacen', 
    loadChildren: () => import('./reports/almacen/almacen.module').then(m => m.RAlmacenModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/caja', 
    loadChildren: () => import('./reports/caja/caja.module').then(m => m.RCajaModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/bar', 
    loadChildren: () => import('./reports/bar/bar.module').then(m => m.RBarModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/informes', 
    loadChildren: () => import('./reports/informes/informes.module').then(m => m.RInformesModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/taller', 
    loadChildren: () => import('./reports/taller/taller.module').then(m => m.RTallerModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/ventas', 
    loadChildren: () => import('./reports/ventas/ventas.module').then(m => m.RVentasModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'reports/utilidades', 
    loadChildren: () => import('./reports/utilidades/utilidades.module').then(m => m.UtilidadesModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'caja', 
    loadChildren: () => import('./pages/caja/caja.module').then(m => m.CajaModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'informes', 
    loadChildren: () => import('./pages/informes/informes.module').then(m => m.InformesModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'almacen', 
    loadChildren: () => import('./pages/almacen/almacen.module').then(m => m.AlmacenModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'camping', 
    loadChildren: () => import('./pages/camping/camping.module').then(m => m.CampingModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'taller', 
    loadChildren: () => import('./pages/taller/taller.module').then(m => m.TallerModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'peluqueria', 
    loadChildren: () => import('./pages/peluqueria/peluqueria.module').then(m => m.PeluqueriaModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'compras', 
    loadChildren: () => import('./pages/compras/compras.module').then(m => m.ComprasModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'logistica', 
    loadChildren: () => import('./pages/logistica/logistica.module').then(m => m.LogisticaModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'contabilidad', 
    loadChildren: () => import('./pages/contabilidad/contabilidad.module').then(m => m.ContabilidadModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'agro', 
    loadChildren: () => import('./pages/agro/agro.module').then(m => m.AgroModule), canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  
  // { path: "**", pathMatch: "full", redirectTo: "utilidades" },
  { path: 'login', component: LoginComponent },

  { path: "**", pathMatch: "full", redirectTo: "login" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
